$(document).ready(function () {
    //SET COOKIES LAW
    if ($.cookie('cookie_bar') == null) {
        $('.content-body').addClass('show-CookieBar');
        $('#cookieBanner').slideDown(1000);
        var CookieSet = $.cookie('cookie_bar', 1, { path: '/' });
    }else{
        $('#cookieBanner').hide();
    }
    $('#cookieBannerClose > a').click(function(){
        $('.content-body').removeClass('show-CookieBar');
        $('#cookieBanner').slideUp(500);
    });
    //SET COOKIES LAW -- END

    $('header .social__index').click(function () {
        var $social = $('header .social');
        if ($social.hasClass('social--open'))
            $social.removeClass('social--open');
        else
            $social.addClass('social--open')
    });
});