$(document).ready(function () {
    var $overlayMob = $('.garanzieMobile__carousel');
    $overlayMob.slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        fade: false,
        adaptiveHeight: true
    });
});