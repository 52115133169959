function toggleOverlay(tsectionName, tpos) {
    var $overlay = $('.wheelOverlay--' + tsectionName + ' > .wheelCont__rel > .overlayBox');
    if (tsectionName == 'close') {

    } else {
        if ($('.wheelOverlay--' + tsectionName).hasClass('wheelCont--disabled')) {
            $('.wheelOverlay--' + tsectionName).removeClass('wheelCont--disabled').addClass('wheelCont--enabled');
            $overlay.slick('setPosition').slick('slickGoTo', tpos, true);
        } else {
            $('.wheelOverlay--' + tsectionName).removeClass('wheelCont--enabled').addClass('wheelCont--disabled');
        }
    }

}

$(document).ready(function () {
    var $overlay = $('.wheelOverlay > .wheelCont__rel > .overlayBox');
    $('.overlayBox__item__close').click(function () {
        var $this = $(this);
        var sectionName = $this.closest('.wheelOverlay').attr('id');
        //console.log(sectionName);
        toggleOverlay(sectionName);
    });
    $overlay.slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        fade: true
    });

    // HOVER BACKGROUND
    $('svg .st1')
        .hover(function () {
            // mouse in
            var $this = $(this);
            var group = $this.data('group');
            var item = $this.data('item');
            $('svg .st2').addClass('st2--disabled');
            $('svg .st1').addClass('st1--disabled');
            $this.removeClass('st1--disabled');
            $('svg .svg__' + group + '--head').removeClass('st2--disabled').addClass('st2--enable');
            $('svg .svg__txt').addClass('svg__txt--disabled');
            $('svg .svg__' + group + '.svg__' + group + '--' + item + '.svg__txt').removeClass('svg__txt--disabled').addClass('svg__txt--enable');

        }, function () {
            //mouse out
            $('svg .st2').removeClass('st2--enable').removeClass('st2--disabled');
            $('svg .st1').removeClass('st1--disabled');
            $('svg .svg__txt').removeClass('svg__txt--disabled').removeClass('svg__txt--enable');
        })
        .click(function () {
            var $this = $(this);
            var group = $this.data('group');
            var item = $this.data('item');
            var pos = $this.data('pos');
            toggleOverlay(group, pos);
        });

    // HOVER TESTO
    $('svg .svg__txt')
        .hover(function () {
            // mouse in
            var $this = $(this);
            var group = $this.data('group');
            var item = $this.data('item');
            $('svg .st2').addClass('st2--disabled');
            $('svg .st1').addClass('st1--disabled');
            $('svg .st1.svg__' + group + '.svg__' + group + '--' + item).removeClass('st1--disabled').addClass('hover');
            //$this.removeClass('st1--disabled');
            $('svg .svg__' + group + '--head').removeClass('st2--disabled').addClass('st2--enable');
            $('svg .svg__txt').addClass('svg__txt--disabled');
            $('svg .svg__' + group + '.svg__' + group + '--' + item + '.svg__txt').removeClass('svg__txt--disabled').addClass('svg__txt--enable');

        }, function () {
            //mouse out
            $('svg .st2').removeClass('st2--enable').removeClass('st2--disabled');
            $('svg .st1').removeClass('st1--disabled').removeClass('hover');
            $('svg .svg__txt').removeClass('svg__txt--disabled').removeClass('svg__txt--enable');
        })
        .click(function () {
            var $this = $(this);
            var group = $this.data('group');
            var item = $this.data('item');
            var pos = $this.data('pos');
            toggleOverlay(group, pos);
        });

    // HOVER INTERNAL "OPZIONE GUIDA LEGGERA"
    $('svg .svg__at.st0')
        .hover(function () {
            // mouse in
            var $this = $(this);
            var group = $this.data('group');
            var item = $this.data('item');
            $('svg .st2').addClass('st2--disabled');
            $('svg .st1').addClass('st1--disabled');
            $('svg .st1.svg__' + group + '.svg__' + group + '--' + item).removeClass('st1--disabled').addClass('hover');
            //$this.removeClass('st1--disabled');
            $('svg .svg__' + group + '--head').removeClass('st2--disabled').addClass('st2--enable');
            $('svg .svg__txt').addClass('svg__txt--disabled');
            $('svg .svg__' + group + '.svg__' + group + '--' + item + '.svg__txt').removeClass('svg__txt--disabled').addClass('svg__txt--enable');

        }, function () {
            //mouse out
            $('svg .st2').removeClass('st2--enable').removeClass('st2--disabled');
            $('svg .st1').removeClass('st1--disabled').removeClass('hover');
            $('svg .svg__txt').removeClass('svg__txt--disabled').removeClass('svg__txt--enable');
        })
        .click(function () {
            var $this = $(this);
            var group = $this.data('group');
            var item = $this.data('item');
            var pos = $this.data('pos');
            toggleOverlay(group, pos);
        });


});

