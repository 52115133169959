function fq_changeFieldDate() {

    // Al cambiamento del campo data valorizza il reale campo data - eleminando le "/"
    var valore = $("#date_desk").val();
    /*
    if(valore.length == "10") {
        var dd = valore.substr(0,2);
        var mm = valore.substr(3,2);
        var yy = valore.substr(6,4);
        $("#date_mob").val(yy+'-'+mm+'-'+dd);
    }
    */
    $("#fq_realDate").val(valore.replace(/\//g, ''));
}

$(document).ready(function () {
    var $targa = $('input[name="plate"]');
    var $data = $('#date');
    // var $data_mob = $('#date_mob');
    // MASCHERE SUI CAMPI INPUT
    $targa.inputmask("AA999BB",{ "placeholder": "AA999BB" });
    $data.inputmask("d/m/y",{ "placeholder": "GG/MM/AAAA" });
    /*$targa.mask('AABBBAA', { 'placeholder':'Targa','translation': {
        A: {pattern: /[A-Za-z]/},
        B: {pattern: /[0-9]/}
    },
        onKeyPress: function (value, event) {
            event.currentTarget.value = value.toUpperCase();
        }
    });*/
    /*$data.mask('AB/CB/DEBB', {
        'placeholder': 'Data di nascita',
        'translation': {
            A: {pattern: /[0-3]/},
            B: {pattern: /[0-9]/},
            C: {pattern: /[0-1]/},
            D: {pattern: /[12]/},
            E: {pattern: /[09]/}
        }
    });*/
    /*$data_mob.change(function(){
        var valore = $(this).val();
        var dd = valore.substr(8,2);
        var mm = valore.substr(5,2);
        var yy = valore.substr(0,4);
        var strValore = dd+'/'+mm+'/'+yy;
        $("#date_desk").val(strValore);
        $("#fq_realDate").val(strValore.replace(/\//g, ''));
    });*/

    // --- VALIDAZIONE FORM
    // dichiarazione controlli
    function getOldDate(years){
        var today = new Date();
        return (new Date(today.getFullYear() - years, today.getMonth(), today.getDate()));
    }
    $.validator.addMethod(
        "checkPlate",
        function(value, element) {
            function isNumeric(num){
                return !isNaN(num)
            }
            var first_plate = value.substr(0,2);
            var second_plate = value.substr(2,3);
            var result = ((value.length >= 6) && (value.length <= 7));
            if (isNumeric(first_plate)) {
                result = false
            }
            if (!isNumeric(second_plate)) {
                result = false
            }
            return result;
        },
        "Targa non valida."
    );
    $.validator.addMethod(
        "checkDate",
        function(value, element) {
            var result = false;
            var comp;
            var d;            var m;
            var y;
            if (value.indexOf('/') > -1) {
                comp = value.split('/');
                d = parseInt(comp[0], 10);
                m = parseInt(comp[1], 10);
                y = parseInt(comp[2], 10);
            }
            if (value.indexOf('-') > -1) {
                comp = value.split('-');
                y = parseInt(comp[0], 10);
                m = parseInt(comp[1], 10);
                d = parseInt(comp[2], 10);
            }
            var date = new Date(y,m-1,d);

            if (date.getFullYear() == y && (date.getMonth() + 1) == m && date.getDate() == d) {
                result = true;
            }
            return (result);
        },
        "Formato data non valido."
    );
    $.validator.addMethod(
        "age",
        function(value, element) {
            var min_bday = getOldDate(90);
            var max_bday = getOldDate(18);
            var bday_array;
            var bday;            if (value.indexOf('/') > -1) {
                bday_array = value.split('/');
                bday = new Date(bday_array[2], bday_array[1] - 1, bday_array[0]);
            }
            if (value.indexOf('-') > -1) {
                bday_array = value.split('-');
                bday = new Date(bday_array[0], bday_array[1] - 1, bday_array[2]);
            }
            return (bday <= max_bday && bday > min_bday);
        },
        "Data non valida"
    );
    $('#checkForm').validate({
        highlight: function(element, errorClass, validClass) {
            $(element).closest('.form-group').addClass('has-danger');
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).closest('.form-group').removeClass('has-danger');
        },
        //onkeyup: true,
        rules: {
            'plate': {
                required: true,
                checkPlate: true
            },
            'date': {
                required: true,
                checkDate: true,
                age:true
            }
        },
        messages: {
            'plate': {
                required: "Campo obbligatorio"
            },
            'date':  {
                required: "Campo obbligatorio"
            }
        },
        submitHandler: function(form, event) {
            return true;
        }
    });

});